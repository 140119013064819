import { RecordId } from '@/api/common/types';

export const getTerritoryUrl = (params: { actusCode: string; cadObjId: RecordId }) => {
  return (
    `https://territory.vvdev.ru/?type=${params.actusCode}&id=${params.cadObjId}` +
    `&actusURL=${process.env.REACT_APP_API_URL}&isAutoGrid=true`
  );
};

export const getTerritoryUrlNew = (params: { actusCode: string; cadObjId: RecordId }) => {
  return `http://territory.uspect.space:3005/object/${params.cadObjId}`;
};
