import React from 'react';
import { Select, SelectProps } from 'antd';
import { ColumnType } from '@/api/Dictionary/Columns/types';

const columnTypes: { value: ColumnType; title: string }[] = [
  {
    value: 'string',
    title: 'Строка',
  },
  {
    value: 'integer',
    title: 'Целое число',
  },
  {
    value: 'decimal',
    title: 'Число с точкой',
  },
  {
    value: 'date',
    title: 'Дата',
  },
  {
    value: 'address',
    title: 'Адрес',
  },
  {
    value: 'external-link',
    title: 'Внешняя ссылка',
  },
];

type Props = SelectProps<string>;
export const ColumnTypeSelect: React.FC<Props> = React.memo((props) => {
  return (
    <Select style={{ width: '100%' }} {...props}>
      {columnTypes.map((c) => (
        <Select.Option key={c.value} value={c.value}>
          {c.title}
        </Select.Option>
      ))}
    </Select>
  );
});
