import { OptionsFilterItem } from '@/components/ServerConfigTable/types';
import { Select } from 'antd';
import React from 'react';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterDropdownFormContainer } from '../common/FilterDropdownFormContainer';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { existOption, inOption, notExistOption, notInOption } from './consts';
import { OptionFilterOptionItem, OptionFilterType, OptionFilterValue } from './types';

export type OptionFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;
  options: OptionFilterOptionItem[];

  filter: OptionFilterValue;
  onChangeFilter: (value: OptionsFilterItem) => void;
  onResetFilter: () => void;
  hasIn?: boolean;
  hasNotIn?: boolean;
  hasExist?: boolean;
};

const getActualPropValue = <ReturnValue extends any>(params: {
  currentFulter: OptionFilterValue;
  changes: OptionFilterValue;
  getProp: (filter: OptionFilterValue) => ReturnValue;
  defaultValue: ReturnValue;
}) => {
  const { currentFulter, changes, getProp, defaultValue } = params;
  return getProp(changes) ?? getProp(currentFulter) ?? defaultValue;
};

const _OptionFilterDropdown = (props: OptionFilterDropdownProps) => {
  const {
    className,
    style,
    options,
    filter,
    onChangeFilter,
    onResetFilter,
    hasIn,
    hasNotIn,
    hasExist,
  } = props;

  React.useEffect(() => {
    setUpdatedFilterState(null);
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] = React.useState<OptionsFilterItem | null>(
    null,
  );

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<OptionFilterType>[] = [];
    if (hasIn) {
      options.push(inOption);
    }
    if (hasNotIn) {
      options.push(notInOption);
    }
    if (hasExist) {
      options.push(existOption, notExistOption);
    }
    return options;
  }, [hasExist, hasIn, hasNotIn]);

  const [selectedFilterType, _setSelectedFilterType] = React.useState<OptionFilterType>('in');
  const changeFilterType = React.useCallback((filterType: OptionFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'options',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  const form = React.useMemo(() => {
    switch (selectedFilterType) {
      case 'exist':
      case 'notExist':
        return null;
      case 'in': {
        const selectValue = getActualPropValue({
          currentFulter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'in' ? filter.payload : undefined;
          },
          defaultValue: [],
        });
        return (
          <FilterDropdownFormContainer>
            <Select
              style={{ width: '100%' }}
              mode='multiple'
              options={options}
              allowClear
              showSearch
              value={selectValue}
              onChange={(value) => {
                if (!value || value.length === 0) {
                  setUpdatedFilterState(null);
                } else {
                  setUpdatedFilterState({ filterAs: 'options', filterType: 'in', payload: value });
                }
              }}
            />
          </FilterDropdownFormContainer>
        );
      }

      case 'notIn': {
        const selectValue = getActualPropValue({
          currentFulter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'notIn' ? filter.payload : undefined;
          },
          defaultValue: [],
        });
        return (
          <FilterDropdownFormContainer>
            <Select
              style={{ width: '100%' }}
              mode='multiple'
              options={options}
              allowClear
              showSearch
              value={selectValue}
              onChange={(value) => {
                if (!value || value.length === 0) {
                  setUpdatedFilterState(null);
                } else {
                  setUpdatedFilterState({
                    filterAs: 'options',
                    filterType: 'notIn',
                    payload: value,
                  });
                }
              }}
            />
          </FilterDropdownFormContainer>
        );
      }
    }
  }, [filter, options, selectedFilterType, updatedFilterState]);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      {form}
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};
export const OptionFilterDropdown = React.memo(_OptionFilterDropdown);
