import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { useGetContractByIdQ, usePatchContactM } from '@/hooks/contracts';
import { ContractPatchRequest } from '@/api/Contracts/types';
import { ContractFields } from '@/components/modals/contracts/common/ContractFields';
import { RecordId } from '@/api/common/types';
import { EditContractFormType } from '@/components/modals/contracts/EditContractModal/types';
import moment from 'moment';

export type EditContractModalProps = { visible: false } | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  onCancel: () => void;
};

export const EditContractModal: React.FC<EditContractModalProps> = React.memo((props) => {
  const { visible } = props;
  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});
export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onCancel, contractId } = props;

  const patchContractM = usePatchContactM();
  const { data: currentContract, isLoading } = useGetContractByIdQ(contractId);
  const [form] = Form.useForm<EditContractFormType>();
  const [changes, setChanges] = useState<EditContractFormType | null>(null);
  useEffect(() => {
    if (currentContract) {
      form.setFieldsValue({
        ...currentContract,
        coordinateSystems: currentContract.coordinateSystems.map((x) => x._id),
        customerCompany: currentContract.customerCompany?._id,
        agents: currentContract.agents.map((x) => x._id),
        engineer: currentContract.engineer._id,
        agreementDate: currentContract ? moment(currentContract.agreementDate) : undefined,
      });
    }
  }, [currentContract, form]);

  const onFinish = useCallback(async () => {
    if (changes) {
      try {
        const { isArchiveContract: _isArchiveContract, ...withoutTrash } = changes;
        const req = {
          id: contractId,
          patch: {
            ...withoutTrash,
            attorney: withoutTrash.attorney === null ? null : withoutTrash.attorney?._id,
            order: withoutTrash.order === null ? null : withoutTrash.order?._id,
            monitoring: withoutTrash.monitoring === null ? null : withoutTrash.monitoring?._id,
            publicPD: withoutTrash.publicPD?.map((pd) => pd._id),
          },
        } as ContractPatchRequest;
        await patchContractM.mutateAsync(req);
        message.success(`Контракт "${currentContract?.title || ''}" изменен`);
        onCancel();
      } catch (e) {
        message.error('При изменения контракта произошла ошибка');
      }
    }
  }, [patchContractM, onCancel, changes, contractId, currentContract?.title]);

  const onChangeFormHandler = React.useCallback(
    (formPatch: EditContractFormType) => {
      setChanges((prev) => ({ ...prev, ...formPatch }));
      form.setFieldsValue(formPatch);
    },
    [form],
  );

  return (
    <Modal
      title={isLoading ? 'Загрузка...' : `${currentContract?.title || ''} Изменение контракта`}
      width={800}
      visible={true}
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ loading: patchContractM.isLoading, disabled: changes == null }}
      cancelText={'Отменить'}
      onCancel={onCancel}
      afterClose={form.resetFields}
      getContainer={false}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={(changes) => {
          for (const key in changes) {
            if (changes[key] === undefined) {
              changes[key] = null;
            }
          }
          setChanges((prevState) => ({ ...prevState, ...changes }));
        }}
        layout={'vertical'}
      >
        <ContractFields onChangeForm={onChangeFormHandler} />
      </Form>
    </Modal>
  );
});
