import {
  BaseServerChildColumnType,
  ServerChildColumnType,
  ServerConfigTableContext,
  TableRecordType,
} from '../types';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import {
  getAddressColumnAddition,
  getDateColumnAddition,
  getDecimalColumnAddition,
  getDocumentCollectionColumnAddition,
  getDocumentColumnAddition,
  getExternalLinkColumnAddition,
  getIntegerColumnAddition,
  getStatusColumnAddition,
  getStringColumnAddition,
} from './additionMappings';
import { dataIndexToStringKey } from './dataIndexToStringKey';
import { getCellClassName } from './getCellClassName';

function getBase(
  serverColumn: BaseServerChildColumnType,
): ControlledTableChildType<TableRecordType> {
  const dataIndexStr = dataIndexToStringKey(serverColumn.dataIndex);

  return {
    key: dataIndexStr,
    title: serverColumn.name,
    dataIndex: serverColumn.dataIndex,
    sortable: serverColumn.isSortable,
    fixed: serverColumn.isFixed ? 'left' : undefined,
    onCell: (record) => ({ className: getCellClassName(record, dataIndexStr) }),
  };
}

export function mapChildServerColumn(
  serverColumn: ServerChildColumnType,
  context: ServerConfigTableContext,
  editable: boolean,
): ControlledTableChildType<TableRecordType> {
  let result = getBase(serverColumn);

  switch (serverColumn.type) {
    case 'string':
      result = { ...result, ...getStringColumnAddition(serverColumn, context, editable) };
      break;
    case 'integer':
      result = { ...result, ...getIntegerColumnAddition(serverColumn, context, editable) };
      break;
    case 'decimal':
      result = { ...result, ...getDecimalColumnAddition(serverColumn, context, editable) };
      break;
    case 'document':
      result = { ...result, ...getDocumentColumnAddition(serverColumn, context, editable) };
      break;
    case 'document-collection':
      result = {
        ...result,
        ...getDocumentCollectionColumnAddition(serverColumn, context, editable),
      };
      break;
    case 'external-link':
      result = { ...result, ...getExternalLinkColumnAddition(serverColumn) };
      break;
    case 'date':
      result = { ...result, ...getDateColumnAddition(serverColumn, context, editable) };
      break;
    case 'address':
      result = { ...result, ...getAddressColumnAddition(serverColumn, context, editable) };
      break;
    case 'status':
      result = { ...result, ...getStatusColumnAddition(serverColumn, context) };
      break;
  }

  return result;
}
